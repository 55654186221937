.FooterlastContainer {
  background-color: #363e47;
  padding: 17px 0px;
}

.backg {
  width: 100%;
  height: auto;
  padding: 10px 0px;
  overflow: hidden;
  background-color: #161b21;
  background-size: cover;
  color: #8d8d8d;
  background-position: 50% 0%;
  background-repeat: no-repeat;
  background-size: cover;
}

.about-footer h4 {
  font-size: 18px !important;
  font-weight: 600 !important;
  padding: 20px 0 0 0 !important;
}

.social-footer {
  margin-top: 30px;
}

.social-items {
  display: inline-block;
  margin: 10px 5px 0px 0px;
}

.social-items .fa {
  color: #fff;
  font-size: 25px;
  transition: 0.3s;
}

.icon-fa {
  display: inline-block;
  font-size: 25px;
  line-height: 32px;
  /* background: #af5689; */
  color: #fff;
  width: 40px;
  height: 40px;
  text-align: center;
  vertical-align: bottom;
  /* border-radius: 5px; */
  /* border: solid 1px #af5689; */
  transition: 0.3s;
}

.newsletter-box {
  margin: 15px 0px 15px 0px;
  position: relative;
}

.newsletter-box form {
  display: flex;
  align-items: center;
}

.newsletter-box .input {
  width: 75%;
  height: 38px;
  background: transparent;
  border: none;
  font-family: inherit;
  color: #737373;
  letter-spacing: 1px;
  text-indent: 0%;
  font-size: 13px;
  font-family: "Raleway", sans-serif;
}

.newsletter-box .button {
  width: 25%;
  height: 38px;
  background-color: #af5689;
  border: none;
  border-radius: 0 5px 5px 0;
  font-family: inherit;
  font-weight: 500;
  color: inherit;
  letter-spacing: 1px;
  cursor: pointer;
  color: #fff;
  font-size: 13px;
  font-family: "Open Sans", sans-serif;
  outline: none;
}
.input:focus {
  outline: none;
  color: White;
}

.categories-list .categoties-items,
.categories-list-2 .categoties-items {
  padding: 5px 0px;
}

.categories-list i,
.categories-list-2 i {
  color: #fff !important;
  margin-right: 10px;
}

.categoties-items-link {
  color: "#A0A2A8" !important;
}

.categoties-items a {
  text-decoration: none !important;
  color: #a0a2a8 !important;
}

.dk-footer-form button:hover {
  cursor: pointer;
}

.btn-style-custom:hover {
  color: #000000 !important;
}

.form-control-custom {
  border-color: #565656 !important;
}

.form-control-custom:focus {
  border-color: #565656 !important;
}

.Typograpy_lastblock_Span {
  font-weight: 700 !important;
}

.head-brand-green-footer {
  font-size: 32px;
  font-weight: 300;
  color: #8e683a;
}

.subscribe-footer small {
  font-weight: 400;
  color: #8e683a;
}

.head-brand-green-span {
  font-size: "18px";
  font-weight: "bold";
  color: "#A0A2A8";
  /* margin-top: "8px" */
}

/* #A0A2A8 */

.test {
  position: absolute;
  right: 40px;
  top: 10px;
  font-size: 20px !important;
}

.plane-icon {
  font-size: 12px;
}

hr.footer-hr {
  border: 1px solid #fff;
  width: 84%;
  margin-right: 55px;
  margin-top: 0px;
}

.navmenu-li:hover {
  color: #f4a950 !important;
}
