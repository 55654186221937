.cross {
    border: 1px solid #C3C5CA;
    width: 15%;
    left: 19px;
    position: absolute;
    top: 28px;
}

cross-price{
    position: relative;
}