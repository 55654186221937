.branded-head {
  padding: 5%;
  font-size: 0;
}

.be-branded {
  font-size: 78px;
  color: #3e4c5d;
  font-family: "BannerHeadBold";
}
.be-branded-brand {
  font-size: 78px;
  color: #3e4c5d;
  font-family: "BannerHeadBold";
}
.be-branded-para {
  font-size: 22px;
  color: #3e4c5d;
  font-family: "BannerDescription";
}
.explore {
  font-size: 26px;
  color: #3e4c5d;
  font-weight: 400;
  /* font-style: italic; */
  font-family: "BannerExplore";
}

@media (max-width: 549px) {
  .main-slider-row {
    display: flex;
    flex-direction: row;
  }
  .branded-head {
    padding-left: 4% !important;
  }
  .be-branded-brand {
    padding-left: 0px !important;
  }
  .be-branded-brand {
    font-size: 25px;
    color: #3e4c5d;
    font-family: "BannerHeadBold";
  }
  .be-branded {
    font-size: 25px;
  }
  .be-branded-para {
    font-size: 10px;
  }
}
