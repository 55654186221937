.flexbox {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

nav {
  position: relative;
}

.hoverdropdown {
  font-weight: 600;
}

.secnav:hover {
  color: #f6ac33 !important;
}

.firstnav:hover {
  color: #f6ac33 !important;
}

.navmenu_custome_li:hover {
  color: #f6ac33 !important;
  background-color: transparent !important;
}

/* .navmenu_custome_li:focus{
    color: #fff !important;
    background-color: #f6ac33 !important;
  } */
/* .navmenu_custome:focus,  */
.navmenu_custome_li:hover {
  color: #f6ac33 !important;
  background-color: #f9fafc !important;
}

.navmenu_custome::before {
  content: "";
  position: absolute;
  height: 10px;
  width: 10px;
  border: 1px solid #eaeaea;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  background-color: #fff;
  border-right: none;
  border-bottom: none;
  top: -6px;
  left: 16px;
}

.navmenu_custome {
  text-transform: uppercase !important;

  padding: 0.8rem 1rem !important;
  width: 250px;
  margin: 15px 0px 0 0px;
  padding: 8px 0px 8px;
  margin-left: 10px;
  border-radius: 7px;
  text-align: left;
  list-style: none;
  box-shadow: 0 0px 3px rgb(0 0 0 / 15%);
}

.navmenu_custome1::before {
  content: "";
  position: absolute;
  height: 10px;
  width: 10px;
  border: 1px solid #eaeaea;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  background-color: #fff;
  border-right: none;
  border-bottom: none;
  top: -6px;
  right: 20px;
}

.navmenu_custome1 {
  padding: 0.8rem 1rem !important;
  width: 250px;
  margin: 15px 0px 0 0px;
  padding: 8px 0px 8px;
  margin-left: 10px;
  border-radius: 7px;
  text-align: left;
  list-style: none;
  box-shadow: 0 0px 3px rgb(0 0 0 / 15%);
}

.navmenu_custome_li {
  padding: 0.8rem 1rem !important;
  border-radius: 4px !important;
  height: 40px;
  padding: 0;
  color: #001b4b !important;

  position: relative;
  -webkit-transition: all 0.3s linear;
  -moz-transition: all 0.3s linear;
  -o-transition: all 0.3s linear;
  transition: all 0.3s linear;
  text-align: start;
  font-size: 12px !important;
  font-weight: 600 !important;
  text-transform: "uppercase" !important;
  letter-spacing: 0.5px !important;
}

.secnav {
  color: #001b4b !important;
}

.firstnav {
  color: #001b4b !important;
}

.activecustom {
  color: #f6ac33 !important;
  border-bottom: 2px solid #001b4b;
}

.activecustomcol {
  color: #f6ac33 !important;
}

.secnav::after {
  display: none !important;
}

.firstnav::after {
  display: none !important;
}

.dropdown:hover .dropdown-menu {
  display: block !important;
}

.dropdown .dropdown-menu {
  transition: ease 1s !important;
}

.disablehover .disablehover-menu {
  display: none !important;
}

.disablehover .enablehover-menu {
  display: block !important;
}

.conCapitalized {
  text-transform: capitalize !important;
}

.fontsizefamily {
  font-weight: 600 !important;
  font-size: 13px !important;
  text-transform: uppercase;
  transition: none;
  padding: 5px 10px;
  letter-spacing: 0.5px !important;
}

.custom-nav {
  border: 1px solid rgb(246, 246, 246);
  /* background-color: #ECF5FF!important; */
}

.form-control:focus {
  color: black !important;
  background-color: none !important;
  border-color: rgb(161, 161, 161) !important;
  outline: 0;
  box-shadow: none !important;
}

.navbar-nav .dropdown-menu {
  font-weight: 600 !important;
  font-size: 12px !important;
  letter-spacing: 0.5px !important;
}

.navbar-light .navbar-nav .nav-link.active,
.navbar-light .navbar-nav .show > .nav-link {
  color: #337ab7 !important;
}

.noti-icon {
  cursor: pointer;
}

.alert-count {
  position: absolute;
  top: -18%;
  right: 0;
  left: 56.7%;
  bottom: 0;
  height: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 15px;
  /* padding: 0.2% 0.3%; */
  border-radius: 7.5px;
  background: red;
  color: white;
  font-size: 9px;
  font-weight: 600;
  /* font-size: 10px; */
}

.my-notifications {
  position: absolute;
  top: 80%;
  left: 57%;
  right: 0;
  bottom: 0;
  /* border: 1px solid black; */
  /* height: 100%; */
  height: max-content;
  width: 40%;
  background-color: #fff;
  z-index: 2;
  border-radius: 8px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.noti-text {
  justify-content: flex-start;
}
.noti-text span {
  font-size: 15px;
  line-height: 20px;
}

.notifications-div {
  width: 100%;
  overflow: scroll;
  height: 400px;
}
::-webkit-scrollbar {
  width: 20px;
}

::-webkit-scrollbar-track {
  background-color: transparent;
}

::-webkit-scrollbar-thumb {
  background-color: #a8bbbf;
  border-radius: 20px;
  border: 8px solid transparent;
  background-clip: content-box;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #ffd7a8;
}

.notification-item div img {
  border-radius: 2rem;
}
.my-notifications hr {
  width: 100%;
  margin: 0 4%;
}
.noti-mark-text {
  color: #ffd7a8;
}

.notification-item:hover {
  /* background-color: #ffd7a8; */
  background-color: rgb(255, 215, 168, 0.7);
  transition: 0.2s ease-in;
  cursor: pointer;
}

.dropdown-item-user {
  color: #001b4b;
  font-size: 18px;
  text-transform: capitalize;
  padding: 9px 16px;
  font-family: "Roboto", sans-serif;
  display: inline-block !important;
  font-weight: 500;
  text-overflow: clip;
  text-decoration: none;
  width: 100%;
}

@media (min-width: 1300px) {
  .badge {
    position: absolute;
    top: 20%;
    right: 0;
    left: 89%;
    bottom: 0;
    height: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 15px;
    /* padding: 0.2% 0.3%; */
    border-radius: 7.5px;
    background: red;
    color: white;
    font-size: 9px;
    /* font-size: 10px; */
  }
}

@media (min-width: 1200px) {
  .container {
    max-width: 1600px !important;
  }

  .customenavcontainer {
    max-width: 1600px !important;
  }
}
