body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
html,
body {
  overflow-x: hidden;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@font-face {
  font-family: "BannerBold";
  src: url("./fonts//avantgarde-bk-bt//AvantGardeDemiBT.ttf");
}
@font-face {
  font-family: "BannerHeadBold";
  src: url("./fonts//playfair-display//PlayfairDisplay-Bold.ttf");
}
@font-face {
  font-family: "BannerDescription";
  src: url("./fonts//proxima-nova//FontsFree-Net-ProximaNova-Regular.ttf");
}
@font-face {
  font-family: "BannerExplore";
  src: url("./fonts//tw-cen-mt//TCMI____.TTF");
}
@font-face {
  font-family: "Quick sand reg";
  src: url("./fonts//quicksand/Quicksand-Regular.ttf");
}
@font-face {
  font-family: "Proxima Nova black";
  src: url("./fonts//proxima-nova//proxima-nova-black.ttf");
}
@font-face {
  font-family: "Quick sand bold";
  src: url("./fonts//quicksand/Quicksand-Bold.ttf");
}
@font-face {
  font-family: "Quick sand medium";
  src: url("./fonts//quicksand/Quicksand-Medium.ttf");
}
@font-face {
  font-family: "Athelas bold";
  src: url("./fonts//athelas/Athelas-Regular.ttf");
}
@font-face {
  font-family: "Athelas Reg";
  src: url("./fonts//athelas/Athelas-Regular.ttf");
}
@font-face {
  font-family: "Avantgrade Demi";
  src: url("./fonts//avantgarde-bk-bt/AvantGardeDemiBT.ttf");
}
@font-face {
  font-family: "Avantgrade Book";
  src: url("./fonts//avantgarde-bk-bt/AvantGardeBookBT.ttf");
}
