.modal-body {
  padding: 0%;
}

.store-locator {
  display: flex;
  flex: 1;
  justify-content: space-between;
}

.store-names {
  width: 30%;
  border-left: 2px solid grey;
  overflow-y: auto;
  height: 400px;
}

.static-store-name {
  font-weight: bold;
  border-bottom: 2px solid grey;
  padding: 7%;
}

.locate-store-info {
  border-bottom: 2px solid grey;
  padding: 7%;
}

.locate-store-info:hover {
  cursor: pointer;
  background-color: rgb(255, 215, 168, 0.7);
  transition: 0.5s ease;
}

.store-map-location {
  width: 70%;
  height: 70vh;
}

.pin-icon {
  font-size: 40px;
  color: red;
}

@media (max-width: 549px) {
  .static-store-name {
    font-size: 13px;
  }
  .locate-store-info {
    padding: 2%;
  }
  .locate-store-info h5 {
    font-size: 10px;
  }
  .locate-store-info p {
    font-size: 8px;
  }
  .store-map-location {
    height: 45vh;
  }
  .store-names {
    height: 300px;
  }
}
