.st_testimonial.st_style1 {
    border-radius: 7px;
    -webkit-transition: all 0.4s ease;
    transition: all 0.4s ease;
    background-color: #fff;
    position: relative;
    padding: 30px 30px;
    /* margin-bottom: 20px; */
    /* border: 1px solid #001b4b; */
  }
  
  .st_testimonial.st_style1 .st_quote {
    position: absolute;
    top: -6px;
    left: 0;
    font-size: 50px;
    line-height: 1em;
    color: #E5E5E5;
    -webkit-transition: all 0.4s ease;
    transition: all 0.4s ease;
  }
  
 
  
  .st_testimonial.st_style1:hover .st_testimonial_name {
    /* color: #f6ac33; */
    text-align: center;
  }
  
  .st_testimonial.st_style1 .st_testimonial_text {
    text-align: center;
    font-size: 15px;
    line-height: 1.6em;
    min-height: 50px;
  }
  
  .st_testimonial.st_style1 .st_testimonial_info {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    text-align: center;
    margin-bottom: 17px;
    color: #fff;
  }
  
  
  .st_testimonial.st_style1 ul {
    padding: 15px 0 0 0;
    margin: 0;
    text-align: center;
  }
  
  .st_testimonial.st_style1 ul li {
    list-style: none;
    display: inline-block;
    padding-left: 5px;
    font-size: 18px;
    color: #fff;
  }
  
  .st_testimonial.st_style1 ul li a:hover {
    color: #f6ac33;
  }
  
  .st_testimonial.st_style1 .st_testimonial_img {
    -webkit-box-flex: 0;
    -ms-flex: none;
    flex: none;
    height: 90px;
    width: 90px;
    border-radius: 50%;
    margin: auto;
    margin-bottom: 10px;
  }
  
  .st_testimonial.st_style1 .st_testimonial_img img {
    height: 100%;
    width: 100%;
    -o-object-fit: cover;
    object-fit: cover;
  }
  
  .st_testimonial.st_style1 .st_testimonial_name {
    font-size: 18px;
    margin-bottom: 1px;
    -webkit-transition: all 0.4s ease;
    transition: all 0.4s ease;
    /* color: #f6ac33; */
    font-weight: 600;
    text-align: center;

  }
  
  .st_testimonial.st_style1 .st_testimonial_name:hover {
    color: #f6ac33;
  }
  
  .st_testimonial.st_style1 .st_testimonial_designation {
    line-height: 1.3em; min-height: 20px;
  }
  .top-box-testamonial{
    margin-top: 8px;
    /* background-color: #F4F5FA !important; */
    height: 100%;
    box-shadow: 2px 3px 11px -2px grey !important
    /* box-shadow: 0px 1px 20px grey; */

   }

   .slick-dots li button:before {
    font-size: 9px;
    line-height: 20px;
    position: absolute;
    top: 0;
    left: 0;
    width: 20px;
    height: 20px;
    content: '•';
    text-align: center;
    opacity: .25;
    color: #000;
}

.slick-dots li.slick-active button:before {
    opacity: .75;
    color: #FCD7A8;
}


@media (max-width:410px) {
  .st_quote{
    padding: 10px 10px !important
  }
}

@media (max-width:320px) {
  .container{
    padding: 0px 0px !important
  }
  .st_testimonial.st_style1{
    padding: 12px 13px !important

  }
}