@font-face {
  font-family: mangaFont;
  src: url(./fonts/manga/mangat.ttf);
}

.focus-in-contract-bck {
  -webkit-animation: focus-in-contract-bck 1s
    cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  animation: focus-in-contract-bck 1s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}
* {
  margin: 0;
  padding: 0;
  text-decoration: none;
}

/* ----------------------------------------------
 * Generated by Animista on 2022-12-19 11:21:9
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation focus-in-contract-bck
 * ----------------------------------------
 */
@-webkit-keyframes focus-in-contract-bck {
  0% {
    letter-spacing: 1em;
    -webkit-transform: translateZ(300px);
    transform: translateZ(300px);
    -webkit-filter: blur(12px);
    filter: blur(12px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateZ(12px);
    transform: translateZ(12px);
    -webkit-filter: blur(0);
    filter: blur(0);
    opacity: 1;
  }
}
@keyframes focus-in-contract-bck {
  0% {
    letter-spacing: 1em;
    -webkit-transform: translateZ(300px);
    transform: translateZ(300px);
    -webkit-filter: blur(12px);
    filter: blur(12px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateZ(12px);
    transform: translateZ(12px);
    -webkit-filter: blur(0);
    filter: blur(0);
    opacity: 1;
  }
}

.truncate-text-6 {
  -webkit-line-clamp: 6;
  /* autoprefixer: off */
  -webkit-box-orient: vertical;
  /* autoprefixer: on */
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
}

.truncate-text-5 {
  -webkit-line-clamp: 5;
  /* autoprefixer: off */
  -webkit-box-orient: vertical;
  /* autoprefixer: on */
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
}

.truncate-text-4 {
  -webkit-line-clamp: 4;
  /* autoprefixer: off */
  -webkit-box-orient: vertical;
  /* autoprefixer: on */
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
}
.truncate-text-3 {
  -webkit-line-clamp: 3;
  /* autoprefixer: off */
  -webkit-box-orient: vertical;
  /* autoprefixer: on */
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
}
.truncate-text-2 {
  -webkit-line-clamp: 2;
  /* autoprefixer: off */
  -webkit-box-orient: vertical;
  /* autoprefixer: on */
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
}
.truncate-text-1 {
  -webkit-line-clamp: 1;
  /* autoprefixer: off */
  -webkit-box-orient: vertical;
  /* autoprefixer: on */
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
}

.cursor-pointer {
  cursor: pointer;
}

.brand-offer-details-page {
  min-height: 100vh;
}
.test {
  position: relative;
  text-align: center;
  color: white;
}

.centered {
  position: absolute;
  top: 45%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
  font-size: 72px;
  color: #fff;
}

.alpha img {
  filter: brightness(60%);
  cursor: pointer;
}

.alpha:hover img {
  transition: 0.5s ease;
  filter: brightness(80%);
  filter: blur(4px);
}

.alpha:hover {
  /* opacity: 0.9; */

  transition: 0.5s ease;
}

.alpha:hover .centered {
  color: #f4a950;
  font-size: 125px;
  transition: 0.5s ease;
  cursor: pointer;
}

.guess-win-heading {
  font-size: 40px;
  font-weight: 600;
}

.guess-win-heading-green {
  font-size: 40px;
  font-weight: 600;
  color: #f4a950;
}
.head-featured {
  font-size: 32px;
  font-weight: 600;
}

.head-featured-white {
  font-size: 32px;
  font-weight: 600;
  color: #ffffff;
}
.head-brand-green {
  font-size: 32px;
  font-weight: 600;
  color: #f4a950;
}
.our-brands-heading span {
  font-size: 60px;
  font-weight: 600;
}
.our-brands-heading span span {
  color: #f4a950;
}

.home-brand-button {
  background-color: #f4a950;
  padding: 13px 25px;
  color: white;
  border: 1px;
  border-radius: 2px;
  font-weight: 500;
  font-size: 18px;
  box-shadow: 1px 2px #f4a950;
  cursor: pointer;
  outline: none;
  border: none;
}

.home-brand-button:focus {
  border: none;
  outline: none;
}

.home-brand-button-oultet {
  background-color: #f4a950;
  color: white;
  padding: 0 8%;
  border: none;
  border-radius: 2px;
  font-weight: 500;
  /* font-size: 21px; */
  /* box-shadow: 1px 2px #f4a950; */
  height: 95%;
  cursor: pointer;
}

.home-brand-button-oultet:focus {
  outline: none;
}

.map-stores-info {
  overflow-y: auto;
  height: 350px;
}

.outlet-details {
  border: 2px solid grey;
  padding: 4%;
}

.outlet-details:hover {
  /* opacity: 0.3; */
  /* color: #fff; */
  cursor: pointer;
  /* border-radius: 0.5rem; */

  background-color: rgb(255, 215, 168, 0.7);

  font-weight: bold;
  transition: 0.5s ease;
}
.brand-store-map {
  width: 100%;
  height: 100%;
}
.game-1-img {
  height: 200px;
  width: 100%;
}

/* .col-lg-12{
    height: 500px;
  } */

.game-heading {
  font-size: 30px;
}
.game-para {
  height: 90px;
  padding-bottom: 30px;
}

.game-card-details {
  height: 325px;
}

.back-cont {
  background-image: url("./statics/images.png");
  /* width: 100%; */
  /* height: auto; */
  background-repeat: no-repeat;
  background-size: cover;
  background-position: initial;
}
.budgut-input-offer {
  background: transparent;
  /* width: 80%; */
  height: 40px;
  border: 2px solid white;
  border-radius: 0%;
  padding: 3px 8px;
  /* color: #f4a950; */
  color: #fff;

  font-size: 12px;
  font-weight: bold;
  outline: none;
}

.budgut-input-offer::placeholder {
  color: #fff;
}

.budgut-input {
  background: transparent;
  /* width: 80%; */
  height: 40px;
  border: 2px solid white;
  border-radius: 0%;
  padding: 3px 8px;
  /* color: #f4a950; */
  font-size: 12px;
  font-weight: bold;
  outline: none;
}

.max-head {
  font-size: 20px;
}

/* @include media-breakpoint-up(sm) {
    .custom-class {
      display: block;
    }
  } */
.media-text-white {
  text-align: right;
}

.user-dp {
  border-radius: 1rem;
  cursor: pointer;
}

.user-logout {
  /* height: 35px; */
  height: max-content;
  width: 13%;
  /* width: max-content; */
  /* width: fit-content; */
  position: absolute;
  top: 25px;
  right: 0;
  left: 85%;
  bottom: 0;
  display: flex;
  /* justify-content: center; */
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;

  border-radius: 0.2rem;
  margin-top: 2%;
  z-index: 2;
  background-color: white;
  padding: 0.3%;
}

.user-logout ul {
  list-style: none;
  width: 100%;
}
.user-logout li {
  padding: 2%;
  width: 100%;
  font-size: 13px;
  /* font-weight: bold; */
  color: #001b4b;
}

.user-logout li:hover {
  background-color: #8aa7d8;
  color: white;
  transition: 0.5s ease;
  cursor: pointer;
}
.user-logout li:first-child:hover {
  background-color: white;
  /* color: white; */
  color: #001b4b;

  transition: 0.5s ease;
  cursor: default;
}

.user-profile-dp {
  border-radius: 2rem;
}

.remove-dp-button {
  /* position: absolute; */
  /* top: 0; */
  /* left: 23%; */
  /* right: 0; */
  /* bottom: 100%; */
  display: flex;
  align-items: center;
  margin-left: 2%;
  cursor: pointer;
  /* background-color: grey; */
  color: grey;
  /* border-radius: 50%; */
}

.user-edit-profile {
  cursor: pointer;
  font-weight: 400;
  font-size: 11px;
  /* text-decoration: underline; */
}

.user-edit-profile:hover {
  cursor: pointer;
  text-decoration: underline;
  color: rgb(91, 91, 125);
}

.your-activity-modal {
  width: 100%;
  padding: 1%;
}

.activity-first-row {
  width: 100%;
}

.favourite-brand-activity {
  /* width: 50%;
  height: 200px;
  /* width: 300px; */
  /* filter: brightness(50%);
  background-image: url(./statics/laura-chouette-AIodgfe90wA-unsplash.png);
  background-repeat: no-repeat;
  background-size: contain; */
  /* border-radius: 1rem; */
  position: relative;
  text-align: center;
  color: white;
  width: 50%;
}

.favourite-brand-activity-img {
  /* width: 100%; */
  width: 100%;
  height: 150px;
  /* filter: brightness(65%); */
}

.favourite-brand-activity-details {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0%;
  left: 0%;
  bottom: 0;
  right: 0;
  /* transform: translate(-50%, -50%); */
  width: 100%;
}

.games-played-activity {
  position: relative;
  text-align: center;
  color: white;
  width: 50%;
}

/* @media (max-width:991.98px) {
  .home-brand-button {

    background-color: #F4A950;
    padding: 0px 30px;
    margin: 10px 0px;
    width: auto;
    color: white;
    border: 1px;
    border-radius: 2px;
    font-weight: 500;
    font-size: 22px;
    box-shadow: 1px 2px #f4a950;
  }
} */

.top-box {
  background-color: #f4f5fa;
  height: 100%;
  box-shadow: 0px 5px 15px -2px grey;
  /* box-shadow: 0px 1px 20px grey; */
}

.toggle-right {
  border: white;
  position: absolute;
  right: 0;
}

.toggle-right-span {
  border: white;
  position: absolute;
  left: 0;
  z-index: 1;
  background: transparent;
  padding: 11px 8px;
}

.select-outlet {
  height: 40px;
  border: 2px solid lightgray;
  border-radius: 0px;
}

.store-sec {
  border: 1px solid lightgray;
}

.back-testamonial {
  background-color: #f8f9fd;
}

.center-childrens {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}

.brand-offer-text {
  font-size: 32px;
  font-weight: 400;
}

.head-brand-green-offers {
  font-size: 48px;
  font-weight: 600;
  color: #f4a950;
}

.head-featured-brand {
  font-size: 48px;
  font-weight: 600;
}

.home-brand-button-brand {
  background-color: #f4a950;
  padding: 15px 25px;
  color: white;
  border: none;
  border-radius: 0px;
  font-weight: 500;
  font-size: 13px;
  cursor: pointer;
  /* box-shadow: 0px 5px 15px -2px grey; */

  /* box-shadow: 1px 2px #F4A950 */
}

.feature {
  position: relative;
  height: 100%;
}

.section-card {
  position: absolute;
  top: 0px;
  /* left: 5%; */
  left: 25px;

  height: 100px;
  width: 100px;
  /* z-index: 1; */
}

.section-logo {
  position: absolute;
  /* z-index: 1; */
  top: 0px;
  /* left: 5%; */
  left: 25px;

  padding: 5px 5px;
  height: 76px;
  width: 100px;
}

.section-logo-when-hover {
  position: absolute;
  /* z-index: 1; */
  top: 0px;
  left: 5%;
  padding: 5px 5px;
  height: 76px;
  width: 100px;
}

.display-all {
  display: block;
}

/* div.feature:hover{
  background-color: #C18B4B;
  z-index: 1;
}
div.feature:hover > img{
  z-index: -1;
} */

/* div.test0>div.feature:hover>div.display-hover {
  visibility: hidden !important;
  opacity: 0 !important;

} */

.hover-text {
  display: none;
}

.hover-text-2 {
  display: none;
}

/* .feature:hover > div.display-background {
  position: absolute;
  background-color: red;
  z-index: 22;
} */

/* ahsan hover effect */

/* .feature:hover+div.display-all .section-card {
  display: none;

}

.feature:hover+div.display-all .section-logo {
  position: absolute;
  top: 60%;
  left: 8%;
  padding: 5px 5px;
  height: 100px;
  width: 170px
}

.feature:hover+div.display-all .hover-text {
  display: block;
  position: absolute;
  top: 60%;
  left: 30%;
  padding: 5px 15px;
  color: #000
}

.feature:hover~.overlay {
  position: absolute;
  top: 0;
  left: 0;
  background-color: #F4A950;
  width: 100%;
  height: 100%;
  z-index: 1;
  opacity: 0.6;

} */

/* ahsan hover effect */

.hover-brand-text {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  /* justify-content: flex-start; */
}

.hover-brand-text h3 {
  font-weight: 900;
  /* font-style: italic;   */
  /* font-size: 50px; */
  display: flex;
  flex-direction: row;
  /* justify-content: flex-start; */

  /* font-size: 5.6rem; */
  /* text-align: center; */
  line-height: 1;
  color: #c6e2ff;
  animation: neon 0.3s ease-in-out infinite alternate;
}
@keyframes neon {
  from {
    text-shadow: 0 0 6px rgba(202, 228, 225, 0.92),
      0 0 30px rgba(202, 228, 225, 0.34), 0 0 12px rgba(30, 132, 242, 0.52),
      0 0 21px rgba(30, 132, 242, 0.92), 0 0 34px rgba(30, 132, 242, 0.78),
      0 0 54px rgba(30, 132, 242, 0.92);
  }
  to {
    text-shadow: 0 0 6px rgba(202, 228, 225, 0.98),
      0 0 30px rgba(202, 228, 225, 0.42), 0 0 12px rgba(30, 132, 242, 0.58),
      0 0 22px rgba(30, 132, 242, 0.84), 0 0 38px rgba(30, 132, 242, 0.88),
      0 0 60px rgba(30, 132, 242, 1);
  }
}

.hover-brand-games-text {
  display: flex;
  /* align-items: flex-end; */
  width: 95%;
  justify-content: flex-start;
  /* margin-top: 1%; */
  /* margin-bottom: 2%; */
}

.featured-brand-games-list {
  list-style: none;
  line-height: 95%;
  font-size: 19px;
}
.featured-brand-games-list img {
  border-radius: 2rem;
  border: 7px solid transparent;
  width: 100px;
  height: 70px;
}

/* haris hover effect */

.hover-div {
  height: 100%;
}

.hover-div:hover {
  background: rgba(244, 169, 80, 0.8);
}

/* .main-cont:hover{
  background: rgba(244,169,80,0.8);

} */

.featured-brand-1 {
  height: 200px;
}

.featured-brand-2 {
  height: 500px;
}
.featured-brand-3 {
  height: 400px;
}
.featured-brand-4 {
  height: 300px;
}

.main-cont {
  height: 100%;
}

.main-cont:hover .feature img {
  opacity: 0.6;

  /* background: rgba(244,169,80,0.8); */
  /* background: #F4A950; */
  display: block;
  width: 100%;
  height: 100%;
  transition: 0.5s ease;
  backface-visibility: hidden;
}

.trending-products-hover-div {
  display: flex;
  justify-content: center;
  flex-direction: column;
  height: 250px;
  align-items: center;
  background-size: cover;
  background-repeat: no-repeat;
  cursor: pointer;
}

.trending-products-hover-div:hover {
  background: rgba(151, 116, 74, 0.2) !important;
  transition: 0.5s ease;
  backface-visibility: hidden;
}

.prod-image {
  width: 100%;
  height: 25vh;
  object-fit: contain;
}

.slick-prev:before {
  color: #001b4b;
}
.slick-next:before {
  color: #001b4b;
}

.main-cont:hover .display-all .section-card {
  opacity: 0.6;
}

.main-cont:hover .display-all .section-logo {
  opacity: 0.6;
}

.main-cont:hover .display-all .hover-text {
  /* opacity: 1; */
  /* display: block; */
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* align-items: center; */
  position: absolute;
  /* top: 60%; */
  left: 5%;
  right: 0;
  bottom: 0;
  /* padding: 1% 5%; */
  color: #000;
  font-weight: bold;
}

.main-cont:hover .display-all .hover-text-2 {
  opacity: 1;
  /* display: block; */
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  position: absolute;
  top: 60%;
  left: 10%;
  padding: 5px 15px;
  color: #000;
}

/* haris hover effect */

/* .feature1:hover + .display-hover{
  display: block;
  position: absolute;
  top: 11px;
  right: 5%;
  height: 100px;
  width: 100px;
  z-index: 1;
  opacity: 1;
} */
.display-hover {
  display: block;
  opacity: 1;
  position: absolute;
  top: 11px;
  right: 5%;
  height: 100px;
  width: 100px;
  z-index: 1;
}

.guess-win {
  background-image: linear-gradient(120deg, #fdfbfb 0%, #ebedee 100%);
}

/* .test1:hover + .display-hover{
  display: none;
  position: absolute;
  top: 11px;
  right: 5%;
  height: 100px;
  width: 100px;
  z-index: 1;

} */

/* .feature:hover .section-logo{
  display: none;
} */
.section-card-channel {
  position: absolute;
  top: 0px;
  right: 5%;
  height: 100px;
  width: 100px;
}

.section-logo-channel {
  position: absolute;
  /* z-index: 1; */
  top: 0px;
  right: 5%;
  padding: 5px 5px;
  height: 76px;
  width: 100px;
}

.section-card-verse {
  position: absolute;
  top: 0px;
  left: 5%;
  height: 100px;
  width: 100px;
}

.section-logo-verse {
  position: absolute;
  /* z-index: 1; */
  top: 0px;
  left: 5%;
  padding: 5px 5px;
  height: 76px;
  width: 100px;
}

/* @media (max-width:1430px) {
  .section-card-verse{
    position: absolute;
    top: 0px;
    left: 5%;
    height: 60px;
    width: 50px;
  }
  .section-logo-verse{
    position: absolute;
    top: 0px;
    left: 5%;
    padding: 5px 5px;
    height: 50px;
    width: 60px
  }

} */

.overlay {
  opacity: 1;
  /* background-color: #F4A950; */
}
.remaining-time-font {
  font-size: 14px;
}

/* .feature:hover ~ .overlay {

  top: 0;
  opacity: 0.6 !important;
  background-color: #F4A950 !important;
  position: absolute;
  height: 100%;
  width: 100%;
} */

/* .feature:hover + .overlay{
} */
.hoddie-trifle {
  font-size: 26px;
  font-weight: 600;
  color: #000;
  font-weight: 500;
}

.hoddie-trifle-2 {
  font-size: 20px;
  font-weight: 600;
  color: #000;
  font-weight: 500;
  line-height: 0.2%;
}

/* ::placeholder {
  color: black;
  opacity: 1;
} */

.vl {
  border-left: 1px solid #000;
  height: 200px;
}

.contact-form-touch .contact-form-getin input,
.contact-form-touch .contact-form-getin textarea {
  border-color: transparent;
  border: 2px solid #83858b;
  padding: 15px 40px;
  background: #fff;
  color: #7a8a9e;
  font-size: 14px;
  margin: 0;
  border-radius: 0px;
  width: 100%;
}

.contact-form-touch .contact-form-getin .form-group {
  margin-bottom: 25px;
}

.contact-form-touch .title {
  font-weight: 700;
  margin-bottom: 40px;
  color: #1a4774;
}

.contact-form-touch .contact-form-getin textarea {
  min-height: 145px;
}

.contact-form-touch .contact-form-getin label {
  display: block;
  color: #83858b;
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 15px;
}

.contact-form-touch .contact-form-getin .form-group {
  position: relative;
  margin-bottom: 35px;
}

.contact-button {
  background-color: #161b21;
  padding: 15px 25px;
  color: white;
  border: none;
  border-radius: 0px;
  font-weight: 500;
  font-size: 21px;
}

.contact-button:hover {
  background-color: #c7c8c9;
  padding: 15px 25px;
  color: #000;
  border: none;
  border-radius: 0px;
  font-weight: 500;
  font-size: 21px;
}
.brand-offers-div {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin: 5px 10px;
  border: 1px solid lightgrey;
  text-align: center;
  background-color: #fff;
  box-shadow: inset 8px 0px 20px -4px lightgrey;
  height: 200px;
}

.brand-offers-image {
  /* height: auto; */
  width: 120px;
}

.favourite-brand-image {
  width: 75px;
  height: auto;
  cursor: pointer;
}

/* .shadow {
  position: absolute;
  height: 100%;
  width: 4px;
  left: 0px;
  top: 0px;
  box-shadow: -4px 0 3px black;
}  */

.back-login {
  background-image: url("./statics/7-login-banner.jpg");
  /* min-height: 950px; */
  min-height: 100vh;
  width: 100%;
  position: relative;
  background-size: cover;
  background-repeat: no-repeat;
}

.opactity-login-image {
  position: absolute;
  top: 0;
  background-color: #000;
  opacity: 0.5;
  height: 100%;
  width: 100%;
  left: 0;
}

.login-row {
  width: 80%;
}

.coming-soon-button {
  font-size: 22px;
  display: inline-block;
  padding: 1em 2em;
  text-align: center;
  color: white;
  fill: none;
  background: linear-gradient(to left, transparent 50%, #fff 50%) right;
  background-size: 200%;
  transition: 0.5s ease-out;
  border: 2px solid #fff;
  font-family: "Quick sand bold";
}

.coming-soon-button:hover {
  background-position: left;
  color: #000 !important;
  /* border: 2px ; */
}

/* .container-fluid {
  background-size: 100% 100%;
  background-attachment: fixed;
} */

/* .container {
  background-size: 100% 100%;
  background-attachment: fixed;
} */

.backgeound-coming {
  background-image: url("./statics/comingsoon/background.jpg") !important;
  min-height: 950px;
  width: 100%;
}

.user-type-back {
  background-image: url("./statics/comingsoon/background-2.jpg") !important;
  min-height: 1000px;
  width: 100%;
}

.brand-parent {
  position: relative;
  height: 100%;
}

.brand-opacity-child {
  position: absolute;
  height: 100%;
  width: 100%;
  background-color: #000;
  z-index: 1;
  top: 0;
  opacity: 0.7;
}

.brand-opacity-child:hover {
  opacity: 0;
}

.brand-child {
  position: absolute;
  top: 5%;
  z-index: 1;
  left: 10%;
  height: 48px;
  width: 146px;
}

.brand-child-button {
  position: absolute;
  top: 5%;
  z-index: 1;
  right: 10%;
  color: #fff;
  cursor: pointer;
  font-family: "Quick sand bold";
}

.brand-child-text {
  position: absolute;
  top: 45%;
  z-index: 1;
  transform: translate(-50%, -50%);
  transition: transform 0.2s;
  left: 50%;
  font-size: 56px;
}

.hot-offer-discount-price {
  text-decoration-line: line-through;
  text-decoration-color: black;
  color: red;
}

.hot-deal-deatails h2 {
  height: 80px;
}

.brand-parent:hover .brand-child-text {
  color: #f4a950 !important;
  font-size: 84px;
  transform: translate(-50%, -50%) scale(1.5);
}

.brand-reg-registration {
  background-image: url("./statics/fachry-zella-devandra-bNSdIkCBJOs-unsplash.jpg") !important;
  min-height: 800px;
  width: 100%;
}

.register-hr {
  border: 1px solid #595a5b !important;
  width: 60% !important;
  margin: 0 !important;
}

.registration-steppar {
}

.steppar-item {
  color: #999b9d;
  cursor: pointer;
}

.steppar-item-active {
  color: #fff;
  cursor: pointer;
}

.brand-form {
  background-color: #babbbc;
  opacity: 0.9;
}

.reg-form-hr {
  border: 1px solid #9d9d9e !important;
  width: 100% !important;
  margin: 0 !important;
}

.upload-logo-button {
  background: #ebecef;
  border: 2px solid #7e8084;
  padding: 8px 30px;
  color: #595c61;
  cursor: pointer;
}

.date-of-birth-input {
  background: transparent;
  width: 100%;
  height: 60px;
  border: 2px solid 7e8084;
  border-radius: 0%;
  padding: 5px 0px;
}

.css-1s2u09g-control {
  -webkit-align-items: center;
  /* background:transparent ; */
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  cursor: default;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  justify-content: space-between;
  min-height: 38px;
  outline: 0 !important;
  position: relative;
  -webkit-transition: all 100ms;
  transition: all 100ms;
  box-sizing: border-box;
}

.budgut-input-multi {
  background: transparent;
  width: 80%;
  height: 60px;
  border: 2px solid #7e8084 !important;
  border-radius: 0%;
  padding: 5px 10px;
}

.css-tj5bde-Svg {
  display: inline-block;
  fill: #000;
  line-height: 1;
  stroke: currentColor;
  stroke-width: 0;
}

.css-1s2u09g-control {
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  /* background-color: hsl(0, 0%, 100%); */
  border-color: #7e8084;
  border-radius: 4px;
  border-style: solid;
  border-width: 2px;
  cursor: default;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  justify-content: space-between;
  min-height: 38px;
  outline: 0 !important;
  position: relative;
  -webkit-transition: all 100ms;
  transition: all 100ms;
  box-sizing: border-box;
}

.google-button {
  background: transparent;
  border: 3px solid #c7c8c9;
  cursor: pointer;
}

.google-button:focus {
  outline: none;
}

.budgut-input-reg {
  position: relative;
  background: transparent;
  width: 80%;
  height: 60px;
  border: 2px solid white;
  border-radius: 0%;
  padding: 5px 10px;
}

.password-icon-change-pw {
  position: absolute;
  top: 38%;
  left: 85%;
  font-size: 22px !important;
  cursor: pointer;
}

.password-icon-login {
  position: absolute;
  top: 50%;
  left: 70%;
  font-size: 22px !important;
  cursor: pointer;
  color: #f4a950;
}

.password-icon {
  position: absolute;
  top: 55px;
  left: 85%;
  font-size: 22px !important;
  cursor: pointer;
}

.create-account-button {
  background: #c7c8c9;
  border: 3px solid #c7c8c9;
}

.create-account-button:hover {
  background: #161b21;
  border: 3px solid #161b21;
  color: #fff !important;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.brand-detail-banner {
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  height: 250px;
  width: 100%;
}

.brand-detail-banner-logo {
  height: 200px;
  border-radius: 50%;
  width: 200px;
  background-color: #fff;
  padding: 45px 30px;
  position: absolute;
  top: 200px;
  left: 90px;
}

/* @media screen and (max-width: 540px) { */

hr.new4 {
  border: 10px solid red;
}

.brand-detail-button-focus {
  background-color: #f4a950;
  padding: 7px 20px;
  color: white;
  border: 2px solid #f4a950;
  outline: none;
  border-radius: 7px;
  font-weight: 500;
  font-size: 15px;
  /* box-shadow: 1px 2px #F4A950; */
  cursor: pointer;
}

.brand-detail-button {
  background-color: transparent;
  padding: 7px 20px;
  color: #f4a950;
  border: 2px solid #f4a950;
  border-radius: 7px;
  font-weight: 500;
  font-size: 15px;
  /* box-shadow: 1px 2px #F4A950; */
  cursor: pointer;
}
.brand-detail-button-focus:focus {
  outline: none;
}
.brand-detail-button:focus {
  outline: none;
}

.card-prod {
  border: 2px solid lightgray;
  /* background-color: #dbfdff !important; */
  /* border-right-style: 2px solid lightgray; */
  /* border-bottom-style: 2px solid lightgray; */
  /* background-image: url('/src//statics/Rectangle 5108.png'); */
  /* background: linear-gradient(to bottom, rgba(186, 205, 203, 0.8) 0%, rgba(213, 215, 215, 0.99) 60%, rgba(215, 215, 215, 0.99) 100%); */
}

.card-prod-when-clicked {
  border: 5px solid #ffaa46;
  /* background-color: #dbfdff !important; */
}
.brand-discounted-price span {
  text-decoration-line: line-through;
  text-decoration-color: black;
  color: red;
}

.cut-price {
  position: absolute;
  left: 0;
  top: -4px;
  width: 100%;
  border: 1px solid lightgrey;
}
.brand-entertainment-section div h2 {
  height: 80px;
}
.brand-entertainment-section div p {
  height: 50px;
}

.card-prod-linear {
  border: 2px solid lightgray;
  background: #ffdf60;
  background: -webkit-linear-gradient(to top, #ffffff 40%, #ffdf60 140%);
  background: linear-gradient(to top, #ffffff 40%, #ffdf60 140%);
}
.card-prod-linear2 {
  border: 2px solid lightgray;
  background: #ffaa46;
  background: -webkit-linear-gradient(to top, #ffffff 40%, #ffaa46 140%);
  background: linear-gradient(to top, #ffffff 40%, #ffaa46 140%);
}

.card-prod-map {
  position: relative;
  background-color: #fcfcfc;
  border: 2px solid lightgray;
  /* height: 500px; */
}
.brand-offers-store-map {
  height: 250px;
  width: 100%;
}

.opact-map {
  position: absolute;
  top: 0;
  background-color: #000;
  height: 250px;
  width: 100%;
  opacity: 0.4;
  cursor: pointer;
}

.card-prod-map h4 {
  height: 70px;
}

.expand {
  position: absolute;
  right: 16px;
  font-size: 32px !important;
  color: #fff;
}

.prod-type-tabs {
  /* background-color: lightgray; */
  border-radius: 10px;
  /* padding: 2% 2%; */
  font-weight: 600;
  /* margin: 0px 0px; */
  font-size: 16px;
  text-align: center;
  /* width: 100%; */
}
.brand-offers-store-map-address {
  height: 120px;
}
.selected-product-detail-image {
  height: auto;
  width: 100%;
}

.single-product-card-image {
  width: 100%;
  height: 30vh;
  object-fit: contain;
}

.gallery-prod {
  cursor: pointer;
  /* Created with https://www.css-gradient.com */
  background-color: #ffffff;
  background-color: -webkit-linear-gradient(bottom, #ffffff, #f1efee);
  background-color: -moz-linear-gradient(bottom, #ffffff, #f1efee);
  background-color: linear-gradient(to top, #ffffff 50%, #f1efee 50%);
  /* border: 1px solid lightcyan; */
  height: 100px;
  width: 120px;
}

.gallery-prod-selected {
  cursor: pointer;
  background-color: #ffffff;
  background-color: -webkit-linear-gradient(bottom, #ffffff, #f2f3fa);
  background-color: -moz-linear-gradient(bottom, #ffffff, #f2f3fa);
  background-color: linear-gradient(to top, #ffffff 50%, #f2f3fa 50%);
  border: 2px solid #ffdf60;
  height: 100px;
  width: 120px;
}

.size-prod {
  border: 1px solid grey;
}

.react-tel-input .form-control {
  position: relative;
  font-size: 14px;
  letter-spacing: 0.01rem;
  /* margin-top: 7px !important; */
  margin-bottom: 0 !important;
  padding-left: 48px;
  margin-left: 0;
  background: #ffffff;
  border: 1px solid #cacaca;
  border-radius: 5px;
  line-height: 25px;
  height: 60px !important;
  width: 100% !important;
  outline: none;
}

.css-1s2u09g-control {
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: hsl(0, 0%, 100%);
  border-color: hsl(0, 0%, 80%);
  border-radius: 4px;
  border-style: solid;
  border-width: 1px;
  cursor: default;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  justify-content: space-between;
  min-height: 60px !important;
  outline: 0 !important;
  position: relative;
  -webkit-transition: all 100ms;
  transition: all 100ms;
  box-sizing: border-box;
}

.error-message {
  color: red !important;
  font-size: 16px !important;
}

.div-bg {
  background: linear-gradient(
    rgba(221, 216, 216, 0.075),
    rgba(255, 255, 255, 0.158)
  );
}

.proxima-font {
  font-family: Proxima-Nova;
}

.quicksand-font {
  font-family: Quicksand;
}

.raleway-font {
  font-family: Raleway;
}

.main-children {
  width: 100%;
  padding: 2rem;
  height: 100%;
}

.circle-grey {
  background-color: #f9dab5;
  border-radius: 50%;
  position: relative;
  height: 100px;
  width: 100px;
  /* left:35% */
}

.count1 {
  position: absolute;
  left: 25px;
  top: 25px;
}

.guess-win-quiz {
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

/* .prod-image{
  position: relative;
}
.hover-prod-image{}
.prod-image:hover + .hover-prod-image{
  position: absolute;
  top: 0;
  left: 0;
  height: 500px;
  color: #000;
  width: 100%;
  z-index: 1;
} */

.modal-label-buy {
  font-weight: 600;
}

.buy-win-input {
  background: #fff;
  padding: 5px 8px;
  color: grey;
  box-shadow: 1px 2px 5px 1px lightgray;
  border: 1px solid lightgray;
}
div[disabled] {
  pointer-events: none;
  opacity: 0.7;
}

.gender-brand-slider-div {
  width: 100%;
  height: 140px;
  /* height: 80%; */
}

.gender-brand-slider {
  border: 15px solid white;
  height: 180px;
}

.gender-brand-slider-img {
  /* width: 250px; */
  /* height: 200px; */
  width: 100%;
  height: 20vh;
  object-fit: contain;
  box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px,
    rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;
  padding: 5%;
  cursor: pointer;
}

.gender-brand-slider-img:hover {
  border: 1px solid grey;
  transition: 0.5s ease;
  width: "100%";
}
.brand-offer-select-brand {
  height: 350px;
  width: 80%;
}

.guess-slider-div {
  width: 100%;
  /* height: 160px; */
  background-color: #fdfbfb;
  height: 55%;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 15px 0px;
  display: flex;
  align-items: center;
}

.guess-slider-img {
  width: 100%;
  height: 100%;
  box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px,
    rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;
  padding: 10%;
  cursor: pointer;
}

.guess-slider {
  border: 15px solid #fdfbfb;
  /* height: 180px; */
  height: 150px;
}

.guess-slider-img:hover {
  border: 2px solid grey;
  /* transition: 0.5s ease; */
  width: "100%";
}

.offer-slider .slick-slider .slick-prev:before,
.offer-slider .slick-slider .slick-next:before {
  color: #fdfbfb;
  font-size: 20px;
}

.offer-slider-image-div {
  display: flex;
  align-items: center;
  justify-content: center;
  background-image: url(./statics/trending.png);
  /* height: 450px; */
  background-size: cover;
  background-repeat: no-repeat;
  border: 15px solid transparent;
  border-radius: 1rem;
}

.offer-slider-logo {
  /* width: 75px;
  height: 35px;
  margin: 10px; */
  width: 100%;
  height: 7vh;
  object-fit: contain;
}

.offer-slider-image {
  width: 100%;
  /* height: 100px; */
  height: 20vh;
  object-fit: contain;
  /* padding: 0% 20%; */
  cursor: pointer;
}

.offer-slider-image-name {
  height: 50px;
}

.filter-content-div {
  /* height: 236px; */
  height: fit-content;
  width: 100%;
  position: absolute;
  top: 40px;
  right: 0;
  left: 0;
  bottom: 0;
  display: flex;
  /* justify-content: center; */
  border: 2px solid #f4a950;
  border-radius: 0.6rem;
  margin-top: 2%;
  z-index: 2;
  background-color: white;
}

.filter-content-ul {
  width: 100%;
  list-style: none;
}

.filter-content-ul li {
  padding: 5%;
  width: 100%;
  border-top: 2px solid #ffaa46;
  font-weight: bold;
  color: #f4a950;
}

.filter-content-ul li:hover {
  background-color: #f4a950;
  color: white;
  transition: 0.5s ease;
  /* cursor: pointer; */
}

.filter-content-ul li:first-child {
  border: none;
}

.filter-content-ul li:first-child:hover {
  background-color: transparent;
  color: #f4a950;
}

.filter-content-div-when-clicked {
  display: none;
}

.filter-logo-slider {
  /* width: 20px; */
  height: 35px;
  border: 3px solid transparent;
}

.filter-selected-logo {
  border: 2px solid #f4a950;
  height: 35px;
}

.gender-filter-div {
  height: fit-content;
  /* height: 57.5%; */
  /* width: 100%;
  position: absolute;
  top: 40%;
  right: 0;
  left: 100%;
  bottom: 0;
  display: flex;
  /* justify-content: center; */
  border: 2px solid #f4a950;
  position: absolute;
  right: -40%;
  width: 100%;
  left: 100%;
  /* bottom: -110%; */
  top: 42%;
  border-radius: 0.6rem;
  margin-top: 2%;
  margin-left: 3%;
  /* z-index: 2; */
  background-color: white;
}
.gender-filter-ul {
  width: 100%;
  list-style: none;
}

.gender-filter-ul li {
  padding: 5%;
  width: 100%;
  border-top: 2px solid #ffaa46;
  font-weight: bold;
  color: #f4a950;
  cursor: pointer;
}

.gender-filter-ul li:first-child:hover {
  background-color: #f4a950;
  color: white;
  transition: 0.5s ease;
  cursor: pointer;
}

.sort-content-div {
  /* height: 241px; */
  height: fit-content;

  width: 100%;
  position: absolute;
  top: 40px;
  right: 0;
  left: 0;
  bottom: 0;
  display: flex;
  /* justify-content: center; */
  border: 2px solid #f4a950;
  border-radius: 0.6rem;
  margin-top: 2%;
  z-index: 2;
  background-color: white;
}

.sort-content-ul {
  width: 100%;
  height: 100%;
  list-style: none;
}

.sort-content-ul li {
  padding: 5%;
  width: 100%;
  border-top: 2px solid #ffaa46;
  font-weight: bold;
  color: #f4a950;
}

.sort-content-ul li:hover {
  background-color: #f4a950;
  color: white;
  transition: 0.5s ease;
  cursor: pointer;
}

.sort-content-ul li:first-child {
  border: none;
}

.sort-content-div-when-clicked {
  display: none;
}

.dropdown-menu li {
  position: relative;
}
.dropdown-menu .dropdown-submenu {
  display: none;
  position: absolute;
  left: 100%;
  top: -7px;
}
.dropdown-menu .dropdown-submenu-left {
  right: 100%;
  left: auto;
}
.dropdown-menu > li:hover > .dropdown-submenu {
  display: block;
}

.contact-info-modal {
  padding: 5%;
}

.contact-info-modal h5 {
  font-weight: bold;
}

.privacy-policy h1 {
  color: #001b4b;
}

.input-login-modal {
  width: 100%;
  height: 42px;
  overflow: hidden;
  float: left;
  border: 1px lightgray solid;
  border-radius: 2px !important;
  padding-left: 12px;
  margin-bottom: 20px;
  color: black;
}
.label-name-login {
  color: #444;
  font-weight: 400;
  font-size: 15px;
}
.modal-title {
  font-family: "Roboto Condensed", sans-serif;
  line-height: 1.55em;
  color: #444;
  font-weight: 600;
  font-size: 20px;
}
.signIn-link {
  color: #1a4774;
}
.text-error {
  margin-top: 0px;
  padding: 0px;
  height: 10px;
  position: absolute;
  top: 65px;
  font-size: 11px;
  color: red;
  text-transform: capitalize;
}

/* forget PW modal */

.height-100 {
  height: 50vh;
}

.card {
  width: 400px;
  border: none;
  height: 300px;
  box-shadow: 0px 5px 20px 0px #d2dae3;
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.card-forget h6 {
  color: red;
  font-size: 20px;
}

.inputs input {
  height: 40px;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}

.card-2 {
  background-color: #fff;
  padding: 10px;
  width: 350px;
  height: 100px;
  bottom: -50px;
  left: 20px;
  position: absolute;
  border-radius: 5px;
}

.card-2 .content {
  margin-top: 50px;
}

.card-2 .content a {
  color: red;
}

.form-control:focus {
  box-shadow: none;
  border: 2px solid red;
}

.validate {
  border-radius: 20px;
  height: 40px;
  background-color: red;
  border: 1px solid red;
  width: 140px;
}
/* forget PW modal */

/* Your Activity Page  */

.headshot {
  flex-shrink: 0;
  margin: 20px;
  height: calc(80px + 3vw);
  width: calc(80px + 3vw);
  border: calc(8px + 0.2vw) solid transparent;
  background-origin: border-box;
  background-clip: content-box, border-box;
  background-size: cover;
  box-sizing: border-box;
  box-shadow: 0 0 5px 5px rgba(0, 0, 0, 0.5);
}

.headshot-3 {
  border-radius: 50%;
  background-image: linear-gradient(to bottom left, #f83600, #f9d423);
}

.headshot-games {
  flex-shrink: 0;
  /* margin: 20px; */
  height: calc(40px + 3vw);
  width: calc(40px + 3vw);
  border: calc(8px + 0.2vw) solid transparent;
  background-origin: border-box;
  background-clip: content-box, border-box;
  background-size: cover;
  box-sizing: border-box;
  /* box-shadow: 0 0 5px 5px rgba(0, 0, 0, 0.5); */
}

.headshot-1 {
  border-radius: 30%;
  background-image: linear-gradient(to bottom left, #f9f047, #0fd850);
}

.favourite-brands-activity-text {
  font-size: 30px;
  font-weight: 600;
}
#customers {
  font-family: Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  width: 100%;
}
#customers th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: left;
  /* background-color: #04aa6d; */
  /* color: white; */
  color: black;
}
/* #customers tr {
  text-align: center;
} */

.game-played-activity-text {
  font-size: 20px;
  font-weight: 600;
}

.gradient-color-text {
  background: #ff0000;
  background: linear-gradient(to left, #ff0000 0%, #ffff00 50%, #ff0000 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: 600;
  font-weight: bold;
}

.rate-review-product {
  text-decoration: underline;
  cursor: pointer;
}

.gradient-font {
  background: #ff0000;
  background: linear-gradient(to left, #ff0000 0%, #000000 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

/* Your Activity Page */
@media (max-width: 385px) {
  .sm-screen {
    font-size: 48px !important;
  }
  .sm-screen-desc {
    padding: 5px 5px !important;
  }
}

@media (max-width: 991px) {
  .sm-screen-image {
    display: flex !important;
    justify-content: start !important;
  }
  .sm-screen-dis {
    display: flex !important;
    justify-content: start !important;
  }
}

@media (max-width: 575px) {
  .vl {
    display: none;
  }
}

@media (max-width: 1042px) {
  .brand-detail-banner-logo {
    top: 280px;
  }
}
@media (max-width: 374px) {
  .brand-detail-banner-logo {
    left: 50px;

    /* top: 280px; */
  }
  .main-container {
    /* padding: 10px 20px !important; */
  }
}

@media (max-width: 570px) {
  .user-profile {
    margin: 0.5rem !important;
  }

  .brand-form {
    padding: 1rem !important;
  }

  .user-type-back {
    padding: 0.5rem !important;
  }
  .gender-brand-slider {
    border: 10px solid white;
  }
  .gender-brand-slider-img {
    padding: 20%;
    width: 50px;
    height: 40px;
    box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 2px 0px,
      rgba(14, 30, 37, 0.32) 0px 2px 6px 0px;
  }
}

@media (max-width: 380px) {
  .password-icon {
    left: 80%;
  }
}

@media (max-width: 1450px) {
  .password-icon {
    left: 80%;
  }
}

@media (max-width: 1200px) {
  .max-head {
    font-size: 15px !important;
  }

  .brand-form {
    padding: 1rem !important;
  }

  .password-icon {
    left: 75%;
  }

  .budgut-input-reg::-webkit-input-placeholder {
    /* WebKit browsers */
    color: transparent;
  }

  .budgut-input-reg:-moz-placeholder {
    /* Mozilla Firefox 4 to 18 */
    color: transparent;
  }

  .budgut-input-reg::-moz-placeholder {
    /* Mozilla Firefox 19+ */
    color: transparent;
  }

  .budgut-input-reg :-ms-input-placeholder {
    /* Internet Explorer 10+ */
    color: transparent;
  }
}

@media (max-width: 990px) {
  .max-head {
    font-size: 19px !important;
  }

  .password-icon {
    left: 90%;
  }

  .budgut-input-reg::-webkit-input-placeholder {
    /* WebKit browsers */
    color: grey;
  }

  .budgut-input-reg:-moz-placeholder {
    /* Mozilla Firefox 4 to 18 */
    color: grey;
  }

  .budgut-input-reg::-moz-placeholder {
    /* Mozilla Firefox 19+ */
    color: grey;
  }

  .budgut-input-reg :-ms-input-placeholder {
    /* Internet Explorer 10+ */
    color: grey;
  }
}

@media (max-width: 766px) {
  .password-icon {
    left: 80%;
  }
}

@media (max-width: 373px) {
  .back-button-freak {
    display: flex !important;
    justify-content: flex-end !important;
    margin-bottom: 5px;
    width: 100% !important;
  }
}

@media (max-width: 1350px) {
  .max-head-sponsor {
    font-size: 12px !important;
  }
}

@media (max-width: 991.98px) {
  .media-text-white {
    text-align: left;
  }
}

@media (max-width: 549px) {
  .centered {
    font-size: 30px;
  }
  .featured-brand-1 {
    height: 80px;
  }

  .featured-brand-2 {
    height: 170px;
  }
  .featured-brand-3 {
    height: 140px;
    padding-right: 40px;
  }
  .featured-brand-4 {
    height: 110px;
  }
  .section-card {
    position: absolute;
    top: 0px;
    /* left: 5%; */
    left: 20px;

    height: 20px;
    width: 20px;
    /* z-index: 1; */
  }

  .section-logo {
    position: absolute;
    /* z-index: 1; */
    top: 0px;
    /* left: 5%; */
    left: 20px;

    padding: 5px 5px;
    height: 20px;
    width: 20px;
  }
  .head-featured-brand {
    font-size: 30px;
  }
  .head-brand-green-offers {
    font-size: 30px;
  }
  .hover-div {
    width: 127%;
  }
  .brand-offer-slider {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .brand-offer-text {
    font-size: 18px;
  }
  .brand-offer-slider-image {
    /* height: 150px;
    width: 180px; */
    width: 10%;
    height: 1vh;
    object-fit: contain;
  }
  .brand-offer-slider-details {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .hot-deal-deatails h2 {
    display: flex;
    justify-content: center;
    height: 55px;
    font-size: 20px;
    line-height: 50px;
  }
  .brand-offer-slider-logo {
    height: 70px;
    width: 140px;
  }
  .description {
    text-align: justify;
  }
  .login-row {
    width: 100%;
  }
  .google-button {
    margin-top: 10px;
    width: 100%;
  }
  .back-login {
    min-height: 100vh;
    width: 100%;
    position: relative;
    background-size: cover;
    background-repeat: no-repeat;
  }
  .find-offers-btn {
    display: flex;
    justify-content: center;
    margin-top: 30px;
  }
  .offer-slider-image-div {
    border: 8px solid transparent;
    border-radius: 0;
  }
  .offer-slider-image-name {
    height: 75px;
  }
  .home-brand-button-oultet {
    display: flex;
    justify-content: center;
    padding: 5% 8%;
    border-radius: 2px;
    height: 100%;
  }
  .brand-stores-mobile-view {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .map-stores-info {
    overflow-y: scroll;
    height: 250px;
  }
  .outlet-details {
    padding: 2%;
  }
  .outlet-details div h4 {
    font-size: 12px;
  }
  .outlet-details div span {
    font-size: 11px;
  }
  .brand-store-map {
    width: 100%;
    height: 300px;
  }
  .game-1-img {
    height: 140px;
  }
  .game-heading {
    display: flex;
    justify-content: center;
    font-size: 20px;
  }
  .game-para {
    height: 70px;
  }
  /* .home-brand-button {
    padding: 13px 25px;
    border: 1px;
    border-radius: 2px;
    font-size: 18px;
    box-shadow: 1px 2px #f4a950;
  } */
  .game-card-details {
    height: 230px;
  }
  .alpha:hover .centered {
    display: none;
  }
  .main-cont:hover .display-all .hover-text {
    display: none;
  }
  .gender-brand-slider-div {
    width: 100%;
    height: 100px;
    /* height: 80%; */
  }

  .gender-brand-slider {
    border: 10px solid white;
    height: 110px;
  }

  .gender-brand-slider-img {
    /* width: 250px; */
    /* height: 200px; */
    width: 100%;
    height: 80%;
    box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px,
      rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;
    padding: 4%;
  }
  .brand-detail-banner {
    height: 130px;
    width: 100%;
  }

  .brand-detail-banner-logo {
    height: 80px;
    border-radius: 50%;
    width: 90px;
    padding: 40px 20px;
    top: 165px;
    left: 20px;
  }
  .brand-detail-banner-logo-mobile {
    position: absolute;
    height: 50px;
    width: 100%;
    top: 15px;
    right: 0px;
  }
  .gallery-prod {
    height: 60px;
    width: 70px;
  }
  .gallery-prod-selected {
    height: 60px;
    width: 70px;
  }
  .selected-product-detail-image {
    height: 240px;
    width: 100%;
  }
  .price-mobile-view {
    font-size: 12px;
  }
  .brand-offers-div {
    height: 100px;
  }
  .brand-offers-image {
    padding: 10px;
  }
  .our-brands-heading {
    margin-top: 20px;
  }
  .our-brands-heading span {
    font-size: 40px;
  }
  .brand-offers-store-map {
    height: 180px;
  }
  .opact-map {
    height: 180px;
  }
  .card-prod-map h4 {
    display: flex;
    align-items: center;
    height: auto;
    font-size: 20px;
  }
  .brand-offers-store-map-address {
    height: auto;
  }
  .brand-entertainment-section {
    display: flex;
    justify-content: center;
  }
  .brand-entertainment-section div h2 {
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px;
  }
  .brand-entertainment-section div p {
    height: auto;
  }
  .brand-offer-select-brand {
    height: 250px;
    width: 80%;
  }
  .guess-slider-div {
    height: 40%;
  }
  .guess-slider {
    border: 5px solid #fdfbfb;
    height: 70px;
  }
  .guess-slider-img {
    height: 100%;
    box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 2px 0px,
      rgba(14, 30, 37, 0.32) 0px 2px 8px 0px;
  }
}

@media (max-width: 285px) {
  .remaining-time-font {
    font-size: 11px;
  }
}
